import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RootState} from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import {HalResource} from "types/halResource.types";
// import {StatsItem} from "../components/DashboardStats/StatsTypes";

export interface EcrfState {
    ecrfVisits: any; // todo model
    // stats: { item: StatsItem[]; totalItems: number };
    stats: { item: any[]; totalItems: number };
    // ecrves: {
    //     itemsPerPage: number;
    //     totalItems: number;
    //     data: EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[];
    // };
}

const initialState: EcrfState = {
    // ecrves: {
    //     itemsPerPage: 0,
    //     totalItems: 0,
    //     data: [],
    // },
    ecrfVisits: [],
    stats: {item: [], totalItems: 0},
};

// type fetchEcrvesParams = {
//     page: number;
// };

// export const fetchEcrves = createAsyncThunk(
//     "ecrf/fetchEcrves",
//     async ({page}: fetchEcrvesParams) => {
//         const response = await axiosSecureInstance.get<HalResource<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead[]>>("/api/ecrves", {
//             headers: {
//                 accept: "application/hal+json",
//             },
//             params: {
//                 pagination: true,
//                 page,
//                 itemsPerPage: 30
//             },
//         });
//         return response.data;
//     }
// );

export const fetchEcrfVisits = createAsyncThunk("ecrf/fetchEcrfVisits", async (id: string) => {
    const response = await axiosSecureInstance.get<any>(
        `/api/ecrves/${id}/visits`
    );
    return response.data;
});

export const fetchStats = createAsyncThunk("ecrf/fetchStats", async () => {
    const response = await axiosSecureInstance.get<any>(`/api/stats`);
    return response.data;
});

// export const addEcrf = createAsyncThunk("users/addEcrf", async () => {
//     try {
//         const response =
//             await axiosSecureInstance.post<EcrfEcrfEcrfReadIdReadSectionReadCommentReadContentReadStatusReadOwnerRead>(
//                 `/api/ecrves`,
//                 {}
//             );
//         return response.data;
//     } catch (error: any) {
//         const {response} = error;
//         const {request, ...errorObject} = response; // take everything but 'request'
//         alert(errorObject?.data?.detail)
//         return errorObject;
//     }
//
// });

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        resetEcrfVisits(state) {
            state.ecrfVisits = initialState.ecrfVisits;
        },
    },
    extraReducers: (builder) => {
        // builder.addCase(fetchEcrves.fulfilled, (state, action) => {
        //   state.ecrves.data = action.payload._embedded?.item || [];
        //   state.ecrves.itemsPerPage = action.payload.itemsPerPage;
        //   state.ecrves.totalItems = action.payload.totalItems;
        // });
        builder.addCase(fetchEcrfVisits.fulfilled, (state: EcrfState, action) => {
            state.ecrfVisits = action.payload;
        });
        builder.addCase(fetchStats.fulfilled, (state: EcrfState, action) => {
            state.stats = action.payload;
        });
    },
});

export const selectEcrfVisits = (state: RootState) => state.dashboard.dashboard.ecrfVisits;
export const selectStats = (state: RootState) => state.dashboard.dashboard.stats;

export const {resetEcrfVisits} = dashboardSlice.actions;

export default dashboardSlice.reducer;
