import React from 'react'
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import BasketItem from "./BasketItem";
import { Wrapper } from "./Basket.styled";
import { useTranslation } from "react-i18next";
import { BasketItemType } from "../redux/basketSlice";
import { ROUTES_BASKET } from "../../../commons/constants/paths";
import { clearBasket } from "../redux/basketSlice";
import {useAppDispatch} from "../../../commons/store/hooks";

export interface BasketProps {
    items: BasketItemType[];
    add?: (item: BasketItemType) => void;
    remove?: (id: number) => void;
}

const Basket = ({ items, add, remove }: BasketProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const calculateTotal = (items: BasketItemType[]) =>
        items.reduce((acc, item) => acc + item.quantity * item.amount, 0);

    return (
        <Wrapper>
            <Box>
                <h2>{t('basket')}</h2>
                {items.length === 0 ? <p>{t('no-items-in-basket')}</p> : null}
                {items.map((item, key) => (
                    <BasketItem
                        id={key}
                        key={key}
                        item={item}
                        add={add}
                        remove={remove}
                    />
                ))}
                <h2>{t('total')}: {calculateTotal(items).toFixed(2)} zł</h2>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => dispatch(clearBasket())}
                >
                    {t('clear-basket')}
                </Button>
                <Button
                    component={Link}
                    variant="contained"
                    size="small"
                    color="primary"
                    to={ROUTES_BASKET.MODULE}
                >
                    {t('go-to-basket')}
                </Button>
            </Box>
        </Wrapper>
    );
};

export default Basket;
