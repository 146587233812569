import { Box, Button } from "@mui/material";
import { ROUTES_PATIENT } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { selectUserProfile } from "core/redux/userSlice";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { roles } from 'commons/auth/roles';
import { FilterPanel } from "./FilterPanel";
import ObservationList from "./ObservationList";
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from "react-i18next";
import { fetchPharmacies } from "features/pharmacies/redux/pharmaciesSlice";
import Patient from "features/patients/helpers/Patient";
import { fetchPatients } from "features/patients/redux/patientsSlice";

const Patients = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    const loadPharmacies = async () => {
        // await dispatch(fetchMedicalCenters({}))
    }

    /**
     * This function is used to fetch patients
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const fetchData = async (initial?: boolean) => {
        setIsDataLoading(true)

        try {
            const params = {
                pagination: true,
                page,
                itemsPerPage: itemsPerPage,
                ...Object.fromEntries(searchParams)
            }

            const response = await dispatch(fetchPatients(params)).unwrap()

            setData(response)
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'})
        }

        setIsDataLoading(false)
    }

    /**
     * This function is used to fetch data of ecrf's visits
     */
    const [isVisitsLoading, setIsVisitsLoading] = useState(false)
    const fetchPharmaciesData = async (id: string) => {
        setIsVisitsLoading(true)
        handleOpen();
        await dispatch(fetchPharmacies({page: 1}))
        setIsVisitsLoading(false)
    }

    /**
     * Check user role and hide export button for
     * All users except: Admin, Coordinator, Monitor, CRO, Sponsor
     */
    const userProfile = useAppSelector(selectUserProfile);

    // const displayAE: boolean = userProfile.roles.some(
    //     (role: string) => (
    //         roles.admin.includes(role) ||
    //         roles.monitor.includes(role) ||
    //         roles.researcher.includes(role)
    //     )
    // );

    // const displayExportButton: boolean = userProfile.roles.some(
    //     (role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
    // );

    const displayPharmacy: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );

    // const displayObservationList: boolean = userProfile.roles.some(
    //     (role: string) => !roles.dataManager.includes(role) && !roles.control.includes(role)
    // );

    useEffect(() => {
        console.log('useEffect 1')
        loadPharmacies().then()
        fetchData().then()
    }, [dispatch, page]);

    useEffect(() => {
        console.log('useEffect 2')
        fetchData().then()
    }, [searchParams]);

    const columns = () => {
        const columns: any[] = [
            // {
            //     title: <Box>{t('screening-number')}</Box>,
            //     render: (row: any) => (
            //         <Box
            //             style={{
            //                 opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%',
            //                 textAlign: "center",
            //             }}>
            //             <Button
            //                 variant="contained"
            //                 color="primary"
            //                 style={{
            //                     paddingLeft: "30px",
            //                     paddingRight: "30px",
            //                 }}
            //                 component={Link}
            //                 to={ROUTES_ECRF.DETAILS(row?.id || '')}
            //             >{row?.screeningNumber}</Button>
            //         </Box>
            //     ),
            //     key: 'participantNumber',
            // },
            // {
            //     title: <Box ml="20px">{t('randomization-date')}</Box>,
            //     render: (row: any) => (
            //         <Box minWidth="120px"
            //              style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
            //             {row?.randomAt && (
            //                 <Box>
            //                     {DateTime.toIso(row?.randomAt, DATETIME_FORMAT)}
            //                 </Box>
            //             )}
            //         </Box>
            //     ),
            //     key: 'randomAt',
            // },
            // {
            //     title: <Box ml="20px">{t('randomization-number')}</Box>,
            //     render: (row: any) => (
            //         <Box minWidth="120px"
            //              style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
            //             {row?.randomAt && <Box>{row?.randomId}</Box>}
            //         </Box>
            //     ),
            //     key: 'randomNumber',
            // },
            {
                title: <Box ml="20px">{t('pharmacy')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Patient.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.pharmacies?.number}</Box>
                ),
                key: 'pharmacy',
            },
            {
                title: <Box ml="20px">{t('status')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Patient.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {/*{t(ecrfStatusDictionary[row?.status])}*/}
                    </Box>
                ),
                key: 'status',
            },
            // {
            //     title: <Box ml="20px">{t('researcher-surname')}</Box>,
            //     render: (row: any) => (
            //         <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}
            //         >{row?._embedded?.owner?.lastname}</Box>
            //     ),
            //     key: 'secondName',
            // },
            // {
            //     title: <Box ml="20px">{t('observation-list')}</Box>,
            //     render: (row: any) => (
            //         <Box style={{opacity: Ecrf.statusClosed(row?.status) ? '50%' : '100%'}}>
            //             <div>
            //                 <Button
            //                     disabled={isVisitsLoading
            //                         // || !displayListObservation
            //                 }
            //                     variant="outlined"
            //                     color="primary"
            //                     size="small"
            //                     startIcon={<FormatListBulletedIcon/>}
            //                     onClick={() => {
            //                         fetchVisits(row?.id);
            //                     }}
            //                 >
            //                     {t('choose')}
            //                 </Button>
            //             </div>
            //         </Box>
            //     ),
            //     key: 'list',
            // },
        ]

        columns.push({
            title: <Box textAlign={'center'} minWidth="60px">{t('queries')}</Box>,
            render: (row: any) => (
                <Box style={{opacity: Patient.statusClosed(row?.status) ? '50%' : '100%', placeItems: "center"}}
                     textAlign={'center'}
                     display="grid">
                    <Box
                        fontWeight={row?.unreadComments > 0 ? 700 : 400}
                        display="flex"
                        textAlign="center"
                    >
                        {row?.unreadComments}
                    </Box>
                </Box>
            ),
            key: 'comments',
        })

        columns.push({
            title: <Box ml="20px">{t('form')}</Box>,
            noWrap: true,
            render: (row: any) => (
                <Box style={{opacity: Patient.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            to={ROUTES_PATIENT.DETAILS(row?.id || "")}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            {t('go')}
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'actions',
        })

        return columns
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel displayMedicalCenter={displayPharmacy} />
            <Table
                pagination={{
                    current: page,
                    pageSize: data.itemsPerPage,
                    total: data.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={data._embedded?.item || []}
                tableName={t('patients')}
                columns={columns()}
                isLoading={isDataLoading}
            />

            {/*<ObservationList loading={isVisitsLoading} open={open} handleClose={handleClose}/>*/}
        </ColoredBox>
    );
};

export default Patients;
