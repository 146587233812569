import React from 'react';
import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DATE_FORMAT } from "commons/constants/env";
import { useAppSelector } from "commons/store/hooks";
import { isValid, parse } from "date-fns";
// import { ecrfStatusDictionary } from "../../../ecrf/views/EcrfForm/Menu/LeftPanel";
import { selectPharmacies } from "../../../pharmacies/redux/pharmaciesSlice";
import { Filter } from "../Filter";
import { useTranslation } from "react-i18next";

export interface FilterPanelProps {
}

export const FilterPanel = (props: FilterPanelProps) => {
    const pharmacies = useAppSelector(selectPharmacies);
    const { t } = useTranslation();

    const filters: any[] = [
        {
            render: (filter: any, fn: any) => (
                <Box display={'flex'} gap={'20px'} alignItems="center">
                    <Tooltip title={t('start-range')} arrow>
                        <>
                            <DatePicker
                                label={t('start')}
                                value={
                                    isValid(parse(filter?.['createdAt[after]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['createdAt[after]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'createdAt[after]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                    -
                    <Tooltip title={t('end-range')} arrow>
                        <>
                            <DatePicker
                                label={t('end')}
                                value={
                                    isValid(parse(filter?.['createdAt[before]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['createdAt[before]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'createdAt[before]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                </Box>
            ),
            key: 'createdAt',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('select-pharmacy')} arrow>
                    <TextField
                        label={t('pharmacy')}
                        select
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                        value={filter?.medicalCenter || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'pharmacy')
                        }
                    >
                        {pharmacies?.data?.map((pharmacy: any, index) => (
                            <MenuItem key={pharmacy?.id} value={pharmacy.id}>
                                {pharmacy?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            ),
            key: 'pharmacy',
            // hide: displayMedicalCenter,
            hide: false,
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('number')} arrow>
                    <TextField
                        label={t('number')}
                        value={filter?.randomId || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'number')
                        }
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                    />
                </Tooltip>
            ),
            key: 'number',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('status')} arrow>
                    <TextField
                        label={t('status')}
                        select
                        fullWidth
                        variant={'outlined'}
                        value={filter?.status || ''}
                        size={'small'}
                        onChange={(event) => fn(event.target.value, 'status')}
                    >
                        {/** Display list of all numbers */}
                        {/*{ecrfStatusDictionary.map((status: string, index: any) => (*/}
                        {/*    <MenuItem key={status} value={String(index)}>*/}
                        {/*        {status}*/}
                        {/*    </MenuItem>*/}
                        {/*))}*/}
                    </TextField>
                </Tooltip>
            ),
            key: 'status',
        },
    ];

    return <Filter elements={filters} />;
};
