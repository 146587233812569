import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useField } from "formik";
import React, { useEffect } from "react";

export interface TextFieldFormikProps
    extends Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'onBlur' | 'error'> {
    name: string;
    status?: boolean;
    data?: any;
    options?: any;
}

const TextFieldFormik = ({name, helperText, status, data, options, ...props}: TextFieldFormikProps) => {
    const [field, meta, helpers] = useField(name);
    const { value } = meta;
    const { setValue } = helpers;

    useEffect(() => {
        if (data && value !== data[0]) {
            setValue(data);

            if (data?.length > 0) {
                setValue(data[0]);
            }
        }
    // }, [ecrf, data])
    }, [data])

    /**
     * This function is used to handle input values and save it to Formik state
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const negativeAllowed = options?.allowNegative !== 'true';
        if (parseInt(e.target.value) < 0 && negativeAllowed) return;

        field.onChange(e);
    }

    return (
        <TextField
            name={field.name}
            variant="outlined"
            value={value}
            disabled={status || options?.disabled}
            onChange={handleChange}
            onBlur={field.onBlur}
            onWheelCapture={(e: any) => {e.target.blur()}}
            error={meta.touched && Boolean(meta.error)}
            helperText={(meta.touched && meta.error) || helperText}
            autoComplete='off'
            {...props}
        />
    );
};

export default TextFieldFormik;