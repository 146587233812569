import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { ProductProductReadIdReadNameReadCreatedRead as Product } from "../../../types/api.types";

export interface PatientState {
    patients: {
        itemsPerPage: number;
        totalItems: number;
        data: any[];
    };
    details: any | null;
    comments: any;
}

const initialState: PatientState = {
    patients: {
        itemsPerPage: 0,
        totalItems: 0,
        data: [],
    },
    details: null,
    comments: {},
};

type FetchPatientParams = {
    page?: number | undefined;
};

export const fetchPatients = createAsyncThunk(
    'patients/fetchPatients',
    async (params: FetchPatientParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<
                HalResource<Product[]>
            >('/api/patients', {
                headers: {
                    accept: 'application/hal+json',
                },
                params: params,
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchPatient = createAsyncThunk(
    'patients/fetchPatient', async (id: string, thunkAPI) => {
        try {
            const response =
                await axiosSecureInstance.get<Product>(
                    `/api/patients/${id}`
                );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchComments = createAsyncThunk(
    'patients/comments',
    async (id: string | null) => {
        const response = await axiosSecureInstance.get<any[]>(`/api/comments/${id}`);
        return response.data;
    }
);

export const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        resetPatients(state) {
            state.patients = initialState.patients;
        },
        resetPatient(state) {
            state.details = initialState.details;
        },
        resetComments(state) {
            state.comments = initialState.comments
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPatients.fulfilled, (state, action) => {
            state.patients.data = action.payload._embedded?.item || [];
            state.patients.itemsPerPage = action.payload.itemsPerPage;
            state.patients.totalItems = action.payload.totalItems;
        });
        builder.addCase(fetchPatient.fulfilled, (state: PatientState, action) => {
            state.details = action.payload;
        });
        builder.addCase(fetchComments.fulfilled, (state, action) => {
            state.comments = action.payload;
        });
    },
});

export const selectPatients = (state: RootState) => state.patients.patients.patients;
export const selectPatient = (state: RootState) => state.patients.patients.details;
export const selectComments = (state: RootState) => state.patients.patients.comments;

export const {
    resetPatients,
    resetPatient,
    resetComments
} = patientsSlice.actions;

export default patientsSlice.reducer;
