import React, { useState } from 'react';
import { Box, Button, Collapse, Tooltip } from "@mui/material";
import { DATE_FORMAT } from "commons/constants/env";
import { useSearchParams } from "react-router-dom";
import { format, isValid } from "date-fns";
import { isEmpty } from "lodash";
import { DeleteSweepRounded, FilterList } from "@mui/icons-material";
import reject from "lodash/reject";
import { StyledGlobalMedicalCentersFiltersButton } from "./Filter.styled";
import { useTranslation } from "react-i18next";

export interface FilterProps<T> {
    elements: Array<{
        render: (row: T, index: number) => React.ReactNode;
        key: string;
        hide?: boolean;
    }>;
};

export const Filter = <T extends object>(props: FilterProps<T>) => {
    const { elements = [] } = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState<any>(Object.fromEntries(searchParams));
    const [show, setShow] = useState<boolean>(searchParams?.size > 0);
    const [loading, setLoading] = useState<boolean>(false);
    const filterElements = reject(elements, (element) => !!element.hide);
    const { t } = useTranslation();

    const handleChange = async (value: any, type: any) => {
        if (isValid(value)) {
            filters[type] = format(value, DATE_FORMAT);
        } else {
            filters[type] = value;
        }

        setFilters((previousState: any) => ({
            ...previousState,
            ...filters,
        }));
    };

    const filter = async () => {
        setLoading(true);

        for (const [key, value] of Object.entries(filters)) {
            if (value === null || value === '') {
                searchParams.delete(key);
            } else {
                searchParams.set(key, String(value));
            }
        }

        setSearchParams(searchParams);
        setLoading(false);
    };

    const clearFilters = async () => {
        setFilters({});
        setSearchParams({});
    };

    return (
        <>
            <Box display={'flex'} gap={'20px'} justifyContent={'end'}>
                <Button style={{whiteSpace: 'nowrap', marginTop: '20px'}}
                    color={'primary'}
                    size={'small'}
                    onClick={() => setShow(!show)}
                    variant={'outlined'}>
                        {show ? t('hide-filters') : t('show-filters')}
                </Button>
            </Box>

            <Collapse in={show} unmountOnExit>
                <Box display={'flex'} alignItems={'center'} mt={1} border={`1px solid #e7e7e7`} p={3} borderRadius={'10px'}>
                    <Box display="grid" gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr'} alignItems="center" style={{gap: "20px"}}>
                        {filterElements.map((element: any) =>
                            element?.render(filters, handleChange)
                        )}

                        <Box display={'flex'} gap={'20px'} alignItems={'center'}>
                            {/*{(show && !isEmpty(filters)) &&*/}
                                <>
                                    <Tooltip title={t('filter')} arrow>
                                        <StyledGlobalMedicalCentersFiltersButton
                                            onClick={(() => filter())}
                                            status={(show && !isEmpty(filters))}
                                        >
                                            <FilterList/> {t('filter')}
                                        </StyledGlobalMedicalCentersFiltersButton>
                                    </Tooltip>
                                    <Tooltip title={t('clear-filters')} arrow>
                                        <StyledGlobalMedicalCentersFiltersButton
                                            onClick={(() => clearFilters())}
                                            status={(show && !isEmpty(filters))}
                                        >
                                            <DeleteSweepRounded/> {t('clear')}
                                        </StyledGlobalMedicalCentersFiltersButton>
                                    </Tooltip>
                                </>
                            {/*}*/}
                        </Box>
                    </Box>
                </Box>
            </Collapse>
        </>
    );
};
