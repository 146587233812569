export const roles= {
    all: [
        'ROLE_SUPER_ADMIN',
        'ROLE_ADMIN',
        'ROLE_DOCTOR',
        'ROLE_PHARMACIST',
        'ROLE_PATIENT',
        'ROLE_USER',
    ],
    pharmacist: [
        'ROLE_PHARMACIST',
        'ROLE_UNBLINDED_NURSE'
    ],
    patient: [
        'ROLE_DOCTOR',
        'ROLE_PHARMACIST',
        'ROLE_PATIENT',
    ],
    admin: [
        'ROLE_SUPER_ADMIN',
        'ROLE_ADMIN'
    ],
};

