import {
    StyledDrawer,
    StyledDrawerContentWrapper,
} from "./BasketBar.styled";
import Basket from "../../../../../features/basket/components/Basket";
import { BasketItemType } from "../../../../../features/basket/redux/basketSlice";

export interface BasketBarProps {
    isOpen?: boolean;
    onClose?: () => void;
    items?: BasketItemType[];
}

const BasketBar = ({isOpen, onClose, items}: BasketBarProps) => {
    return (
        <StyledDrawer anchor="right" open={isOpen} onClose={onClose}>
            <StyledDrawerContentWrapper>
                <Basket items={items || []} />
            </StyledDrawerContentWrapper>
        </StyledDrawer>
    );
};

export default BasketBar;
