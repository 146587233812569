import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import { Pharmacy } from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { omit } from "lodash";
import axiosPublicInstance from "commons/axios/axiosPublicInstance";

export interface PharmacyState {
  pharmacies: {
    itemsPerPage: number;
    totalItems: number;
    data: Pharmacy[];
  };
  details: Pharmacy | null;
}

const initialState: PharmacyState = {
  pharmacies: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  details: null,
};

interface PharmacyValues {
  name: string;
  address: {
    street: string;
    number: string;
    city: string;
    postcode: string;
  };
  longitude: string;
  latitude: string;
  number: string;
}

type FetchPharmacyParams = {
  page?: number | undefined;
};

export const fetchPublicPharmacies = createAsyncThunk(
  'pharmacies/fetchPublicPharmacies',
  async (params: FetchPharmacyParams | undefined, thunkAPI) => {
    try {
      const response = await axiosPublicInstance.get<
        HalResource<Pharmacy[]>
      >('/api/pharmacies', {
        headers: {
          accept: 'application/hal+json',
        },
        params: params,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPharmacies = createAsyncThunk(
  'pharmacies/fetchPharmacies',
  async (params: FetchPharmacyParams | undefined, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.get<
        HalResource<Pharmacy[]>
      >('/api/pharmacies', {
        headers: {
          accept: 'application/hal+json',
        },
        params: params,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchPharmacy = createAsyncThunk(
  'pharmacies/fetchPharmacy',
  async (id: string) => {
    const response = await axiosSecureInstance.get<Pharmacy>(
      `/api/pharmacies/${id}`
    );
    return response.data;
  }
);

export const addPharmacy = createAsyncThunk(
  'pharmacies/add',
  async (values: PharmacyValues, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post(`/api/pharmacies`, {
        ...values,
        address: {
          ...omit(values.address, "country"),
          city: `/api/cities/${values.address?.city}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type editPharmacyParams = {
  id: string | undefined;
  values: PharmacyValues;
};

export const editPharmacy = createAsyncThunk(
  'pharmacies/edit',
  async ({ id, values }: editPharmacyParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put(
        `/api/pharmacies/${id}`,
        {
          ...values,
          address: {
            ...omit(values.address, 'country'),
            city: `/api/cities/${values.address?.city}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deletePharmacy = createAsyncThunk(
  'pharmacies/delete',
  async (id: string) => {
    await axiosSecureInstance.delete(`/api/pharmacies/${id}`);
  }
);

export const pharmaciesSlice = createSlice({
  name: 'pharmacies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPharmacies.fulfilled, (state, action) => {
      state.pharmacies.data = action.payload._embedded?.item || [];
      state.pharmacies.itemsPerPage = action.payload.itemsPerPage;
      state.pharmacies.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchPharmacy.fulfilled, (state, action) => {
      state.details = action.payload;
    });
  },
});

export const selectPharmacies = (state: RootState) => state.pharmacies.pharmacies.pharmacies;

export const selectPharmacyDetails = (state: RootState) => state.pharmacies.pharmacies.details;

export default pharmaciesSlice.reducer;
