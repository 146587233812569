import { Button } from "@mui/material";
import { Wrapper } from "./BasketItem.styled";
import { useTranslation } from "react-i18next";
import { BasketItemType, increaseBasketItem, decreaseBasketItem, removeBasketItem } from "../redux/basketSlice";
import { useAppDispatch } from "../../../commons/store/hooks";
import { Remove } from "@mui/icons-material";

export interface BasketItemProps {
    id: any;
    item: BasketItemType;
    add?: (item: BasketItemType) => void;
    remove?: (id: number) => void;
}

const BasketItem = ({ id, item, add, remove }: BasketItemProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const calculateTotal = (price: any, quantity: any) =>
        Number(price * quantity).toLocaleString('pl-PL');

    return (
        <Wrapper>
            {/*<img src={item.image} alt={item.name}/>*/}
            <div>
                <h3>{item.name}</h3>
                <div className="information">
                    <p>{t('price')}: {item.amount} zł</p>
                    <p>{t('total')}: {calculateTotal(item.amount, item.quantity)} zł</p>
                </div>
                {/*<div className="buttons">*/}
                {/*    <input*/}
                {/*        id={id}*/}
                {/*        className="quantity-ctrl"*/}
                {/*        type="number"*/}
                {/*        defaultValue={item.quantity}*/}
                {/*        min="0"*/}
                {/*        onChange={(e) => changeQuantityBasketItem(item?.id, e.target.value)}/>*/}
                {/*</div>*/}
                <div className="buttons">
                    <Button
                        size="small"
                        disableElevation
                        variant="contained"
                        onClick={() => dispatch(decreaseBasketItem(item?.id))}
                    >
                        -
                    </Button>
                    <p>{item.quantity}</p>
                    <Button
                        size="small"
                        disableElevation
                        variant="contained"
                        onClick={() => dispatch(increaseBasketItem(item?.id))}
                    >
                        +
                    </Button>
                </div>
                <div className="buttons">
                    <Button
                        size="small"
                        disableElevation
                        variant="contained"
                        onClick={() => dispatch(removeBasketItem(item?.id))}
                    >
                        <Remove />
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};

export default BasketItem;
