import React from "react";
import { createRoot } from "react-dom/client";
import App from "./core/App";
import { store } from "./commons/store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import pl from "date-fns/locale/pl";
import { plPL } from '@mui/x-date-pickers/locales';
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import theme from "./commons/theme/customMuiTheme";
import { BrowserRouter as Router } from "react-router-dom";
import CreateGlobalStyle from "./commons/styles/globalStyles.styled";
import { SnackbarProvider } from "notistack";
import { EVENT_STORE_DEBUG_MODE } from "./commons/constants/env";
import './i18n/config';
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react'

let persistor = persistStore(store)

declare global {
  interface Window {
    eventStoreDebugMode: boolean
  }
}
window.eventStoreDebugMode = EVENT_STORE_DEBUG_MODE === 'true';

process.env.TZ = 'Europe/Warsaw';

const IndexApp = () => {
  return (
    // <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StyledThemeProvider theme={theme}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={pl}
                    localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: "bottom", horizontal: "left"}}>
                    <Router>
                      <CreateGlobalStyle />
                      <App />
                    </Router>
                  </SnackbarProvider>
                </LocalizationProvider>
              </StyledThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    // </React.StrictMode>
  );
};

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<IndexApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();