import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import {Order} from "../../../types/api.types";

export interface OrderState {
    orders: {
        itemsPerPage: number;
        totalItems: number;
        data: any[];
    };
    details: any | null;
}

const initialState: OrderState = {
    orders: {
        itemsPerPage: 0,
        totalItems: 0,
        data: [],
    },
    details: null,
};

type FetchOrderParams = {
    page?: number | undefined;
};

export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (params: FetchOrderParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<
                HalResource<Order[]>
            >('/api/orders', {
                headers: {
                    accept: 'application/hal+json',
                },
                params: params,
            });

            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchOrder = createAsyncThunk(
    'orders/fetchOrder', async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<Order>(`/api/orders/${id}`);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
});

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        resetOrders(state) {
            state.orders = initialState.orders;
        },
        resetOrder(state) {
            state.details = initialState.details;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders.data = action.payload._embedded?.item || [];
            state.orders.itemsPerPage = action.payload.itemsPerPage;
            state.orders.totalItems = action.payload.totalItems;
        });
        builder.addCase(fetchOrder.fulfilled, (state, action) => {
            state.details = action.payload;
        });
    },
});

export const selectOrders = (state: RootState) => state.orders.orders.orders;
export const selectOrder = (state: RootState) => state.orders.orders.details;

export const {
    resetOrders,
    resetOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;
