import { Button } from "@mui/material";
import { Wrapper } from "./Product.styled";
import { useTranslation } from "react-i18next";
import { BasketItemType } from "../../basket/redux/basketSlice";
import ReactHtmlParser from "react-html-parser";

type ProductProps = {
    item: BasketItemType;
    handleAddToBasket: (item: BasketItemType) => void;
};

const Product = ({ item, handleAddToBasket }: ProductProps) => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            {/*<img src={item.image} alt={item.name} />*/}
            <div>
                <h3>{item.name}</h3>
                <p>{ReactHtmlParser(item.description as string)}</p>
                <h3>{item.price}</h3>
            </div>
            <Button onClick={() => handleAddToBasket(item)}>{t('add-to-basket')}</Button>
        </Wrapper>
    );
};

export default Product;
