import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { ROUTES_BASKET } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import ColoredBox from "components/ColoredBox";
import ConfirmationAction from "components/ConfirmationAction";
import Table from "components/Table";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import { BasketItemType, removeBasketItem } from "../../redux/basketSlice";

const BasketFull = () => {
    const dispatch = useAppDispatch();
    const basketState = (state: any) => state.basket.basket;
    const basket = useSelector(basketState);
    const { t } = useTranslation();
    const [page, setPage] = useState(1);

    const handleDelete = async (id: any) => {
        await dispatch(removeBasketItem(id));
    };

    return (
        <DashboardLayoutWrapper>
            <ColoredBox p={1} maxWidth="100%" ml="auto" mr="auto">
                <Table
                    // pagination={{
                    //     pageSize: basket.itemsPerPage,
                    //     current: page,
                    //     total: basket.totalItems,
                    // }}
                    onChangePage={(page) => setPage(page)}
                    data={basket?.items || []}
                    tableName={t('basket')}
                    columns={[
                        {
                            title: t('image'),
                            render: (row: BasketItemType) => row.image,
                            key: 'image',
                        },
                        {
                            title: t('name'),
                            render: (row: BasketItemType) => row.name,
                            key: 'name',
                        },
                        {
                            title: t('price'),
                            render: (row: BasketItemType) => row.amount,
                            key: 'amount',
                        },
                        {
                            title: t('quantity'),
                            render: (row: BasketItemType) => row.quantity,
                            key: 'quantity',
                        },
                        {
                            title: t('total'),
                            render: (row: BasketItemType) => (row.quantity * row.amount),
                            key: 'total',
                        },
                        {
                            title: t('actions'),
                            noWrap: true,
                            render: (row: BasketItemType) => (
                                <div>
                                    <ConfirmationAction
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        {({handleClick}) => (
                                            <Button
                                                type="button"
                                                onClick={handleClick}
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                            >
                                                {t('delete')}
                                            </Button>
                                        )}
                                    </ConfirmationAction>
                                </div>
                            ),
                            key: 'actions',
                        },
                    ]}
                />
            </ColoredBox>
        </DashboardLayoutWrapper>
    );
};

export default BasketFull;
