import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";

export interface BasketState {
    details: BasketItemType | null;
}

export type BasketProps = {
    id?: string | null
    items?: any[]
    isOpen: boolean
};

export type BasketItemType = {
    id: string;
    category?: string;
    image?: string;
    name: string;
    description?: string;
    amount: number;
    price: number;
    quantity: number;
};

export interface StateProps {
    items: BasketItemType[];
    tax: number,
    quantity: number;
}

const initialState: StateProps = {
    items: [],
    tax: 0,
    quantity: 0,
}

export const basketSlice = createSlice({
    name: 'basket',
    initialState,
    reducers: {
        addBasketItem(state, action) {
            const item = state.items.find((item) => item.id === action.payload.id);
            if (item) {
                item.quantity++;
            } else {
                state.items.push({ ...action.payload, quantity: 1 });
            }
            state.quantity++;
        },
        increaseBasketItem: (state, action) => {
            state.items = state.items.map((item) => {
                if (item.id === action.payload) {
                    return {
                        ...item,
                        quantity: item.quantity + 1
                    };
                }

                return item;
            });
            state.quantity++;
        },
        decreaseBasketItem: (state, action) => {
            const _item = state.items.find((item) => item.id === action.payload);
            if (_item?.quantity && _item?.quantity > 1) {
                state.items = state.items.map((item) => { // <-- shallow copy cart array
                    if (item.id === action.payload) {
                        return {
                            ...item,
                            quantity: item.quantity - 1
                        };
                    }

                    return item;
                });
                state.quantity--;
            }
        },
        removeBasketItem: (state, action) => {
            state.items = state.items.filter(item => {
                return item.id !== action.payload
            })
        },
        clearBasket: (state) => {
            state.items = [];
            state.quantity = 0;
        },
        calculateTax: (state, action) => {

        },
        // resetBasket(state) {
        //     state.details = initialState.details;
        // },
    },
    // extraReducers: (builder) => {
    //     builder.addCase(basket.fulfilled, (state, action) => {
    //         state.details = action.payload;
    //     });
    // },
});

export const selectBasket = (state: RootState) => state.basket.basket;

export const {
    addBasketItem,
    increaseBasketItem,
    decreaseBasketItem,
    removeBasketItem,
    clearBasket
} = basketSlice.actions;

export default basketSlice.reducer;
