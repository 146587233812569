import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { ROUTES_PRODUCT } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import { Link, useSearchParams } from "react-router-dom";
import { selectUserProfile } from "core/redux/userSlice";
import { roles } from 'commons/auth/roles';
import { FilterPanel } from "./FilterPanel";
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from "react-i18next";
import { fetchProducts } from "features/products/redux/productsSlice";
// import { Product as ProductHelper } from "features/products/helpers/Product";
import Product from "../../../products/components/Product";
import { addBasketItem, BasketItemType } from "../../../basket/redux/basketSlice";
import LoadingBox from "../../../../components/LoadingBox";

const Products = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    /**
     * This function is used to fetch orders
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const fetchData = async (initial?: boolean) => {
        setIsDataLoading(true)

        try {
            const params = {
                pagination: true,
                page,
                itemsPerPage: itemsPerPage,
                ...Object.fromEntries(searchParams)
            }

            const response = await dispatch(fetchProducts(params)).unwrap()

            setData(response)
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'})
        }

        setIsDataLoading(false)
    }

    const handleAddToBasket = async (item: BasketItemType) => {
        dispatch(addBasketItem({
            id: item.id,
            name: item.name,
            description: item.description,
            amount: item.amount,
            price: item.price,
            image: item.image,
            // quantity: item.quantity,
        }));
    };

    useEffect(() => {
        fetchData().then()
    }, [dispatch, page]);

    useEffect(() => {
        fetchData().then()
    }, [searchParams]);

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel/>
            {isDataLoading ? (
                <LoadingBox/>
            ) : (
                <Grid container spacing={3}>
                    {data._embedded?.item?.map((item: any) => (
                        <Grid item key={item.id} xs={12} sm={4}>
                            <Product item={item} handleAddToBasket={handleAddToBasket} />
                        </Grid>
                    ))}
                </Grid>
            )}
        </ColoredBox>
    );
};

export default Products;
