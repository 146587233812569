import { ReactNode } from "react";
import {
    StyledDashboardWrapper,
    StyledContent,
} from "./DashboardLayoutWrapper.styled";
import Container from "@mui/material/Container";
import FooterDefault from "components/Footer/Default";
import Nav from "./components/Nav";

export interface DashboardLayoutWrapperProps {
    centerChildrenVertically?: boolean;
    children: ReactNode;
}

const DashboardLayoutWrapper = ({children, centerChildrenVertically}: DashboardLayoutWrapperProps) => {
    return (
        <StyledDashboardWrapper>
            <Nav/>
            <StyledContent centerChildrenVertically={centerChildrenVertically}>
                <Container maxWidth={'xl'}>
                    <>{children}</>
                </Container>
            </StyledContent>
            <FooterDefault/>
        </StyledDashboardWrapper>
    );
};

export default DashboardLayoutWrapper;
