import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import {
    CityCityReadIdReadNameRead,
    CountryCountryReadIdReadNameRead,
} from "types/api.types";
import axiosPublicInstance from "../../commons/axios/axiosPublicInstance";
import { HalResource } from "../../types/halResource.types";

export interface DictionariesState {
  agreements: {
    itemsPerPage: number;
    totalItems: number;
    data: any[];
  };
  countries: CountryCountryReadIdReadNameRead[];
  cities: CityCityReadIdReadNameRead[];
}

const initialState: DictionariesState = {
  agreements: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  countries: [],
  cities: [],
};

export const fetchAgreements = createAsyncThunk(
  'dictionaries/fetchAgreements',
  async (params: any) => {
    const response = await axiosPublicInstance.get<HalResource<any[]>>(
        '/api/agreements',
        {
            headers: {
                accept: 'application/hal+json',
            },
            params: params,
        });

    return response.data;
  }
);

export const fetchCountries = createAsyncThunk(
  'dictionaries/fetchCountries',
  async () => {
    const response = await axiosPublicInstance.get('/api/countries');

    return response.data;
  }
);

export const fetchCities = createAsyncThunk(
  'dictionaries/fetchCities',
  async () => {
    const response = await axiosPublicInstance.get('/api/cities');

    return response.data;
  }
);

export const fetchCity = createAsyncThunk(
  'dictionaries/fetchCity',
  async (countryId: string) => {
    const response = await axiosPublicInstance.get('/api/cities', {
      params: { country: countryId },
    });

    return response.data;
  }
);

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAgreements.fulfilled, (state, action) => {
      state.agreements.data = action.payload._embedded?.item || [];
      state.agreements.itemsPerPage = action.payload.itemsPerPage;
      state.agreements.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.cities = action.payload;
    });
  },
});

export const selectAgreements = (state: RootState) => state.core.dictionaries.agreements;

export const selectCountries = (state: RootState) => state.core.dictionaries.countries;

export const selectCities = (state: RootState) => state.core.dictionaries.cities;

export default dictionariesSlice.reducer;
