import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import {
    CommentCommentCommentReadIdReadContentReadStatusReadOwnerReadUpdatedReadCreatedReadNameReadUserDataRead as CurrentData
} from "types/api.types";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";

// @todo one url variable per module or from route list

export interface ResourcesState {
  comments: {
    itemsPerPage: number;
    totalItems: number;
    data: CurrentData[];
  };
  commentDetails: CurrentData | null;
}

const initialState: ResourcesState = {
  comments: {
    itemsPerPage: 0,
    totalItems: 0,
    data: [],
  },
  commentDetails: null,
};

interface ResourceValues {
  name: string;
}

type fetchResourcesParams = {
  page: number;
};

export const fetchResources = createAsyncThunk(
  "comments/fetchResources",
  async ({ page }: fetchResourcesParams) => {
    const response = await axiosSecureInstance.get<
      HalResource<CurrentData[]>
    >("/api/comments", {
      headers: {
        accept: "application/hal+json",
      },
      params: {
        page,
        pagination: true,
      },
    });
    return response.data;
  }
);

export const fetchResource = createAsyncThunk(
  "comments/fetchResource",
  async (id: string) => {
    const response = await axiosSecureInstance.get<CurrentData>(
      `/api/comments/${id}`
    );
    return response.data;
  }
);

export const addResource = createAsyncThunk(
  "comments/addResource",
  async (values: ResourceValues, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post(`/api/comments`, {
        ...values,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type editResourceParams = {
  id: string;
  values: ResourceValues;
};

export const editResource = createAsyncThunk(
  "comments/editResource",
  async ({ id, values }: editResourceParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put(
        `/api/comments/${id}`,
        {

        }
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteResource = createAsyncThunk(
  "comments/deleteResource",
  async (id: string) => {
    await axiosSecureInstance.delete(`/api/comments/${id}`);
  }
);

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResources.fulfilled, (state, action) => {
      state.comments.data = action.payload._embedded?.item || [];
      state.comments.itemsPerPage = action.payload.itemsPerPage;
      state.comments.totalItems = action.payload.totalItems;
    });
    builder.addCase(fetchResource.fulfilled, (state, action) => {
      state.commentDetails = action.payload;
    });
  },
});

export const selectResources = (state: RootState) =>
  state.comments.comments.comments;

export const selectResourceDetails = (state: RootState) =>
  state.comments.comments.commentDetails;

export default commentsSlice.reducer;
