import { Box, Button } from "@mui/material";
import { ROUTES_CONSULTATION } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import ColoredBox from "components/ColoredBox";
import Table from "components/Table";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { selectUserProfile } from "core/redux/userSlice";
import CallMadeIcon from '@mui/icons-material/CallMade';
import { roles } from 'commons/auth/roles';
import { FilterPanel } from "./FilterPanel";
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from "react-i18next";
import { fetchPharmacies } from "features/pharmacies/redux/pharmaciesSlice";
import Consultation from "features/consultations/helpers/Consultation";
import { fetchConsultations } from "features/consultations/redux/consultationsSlice";

const Consultations = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    })

    /**
     * This function is used to fetch orders
     */
    const [isDataLoading, setIsDataLoading] = useState(false)
    const fetchData = async (initial?: boolean) => {
        setIsDataLoading(true)

        try {
            const params = {
                pagination: true,
                page,
                itemsPerPage: itemsPerPage,
                ...Object.fromEntries(searchParams)
            }

            const response = await dispatch(fetchConsultations(params)).unwrap()

            setData(response)
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'})
        }

        setIsDataLoading(false)
    }

    /**
     * This function is used to fetch data of ecrf's visits
     */
    const [isVisitsLoading, setIsVisitsLoading] = useState(false)
    const fetchPharmaciesData = async () => {
        setIsVisitsLoading(true)
        await dispatch(fetchPharmacies({}))
        setIsVisitsLoading(false)
    }

    /**
     * Check user role and hide export button for
     * All users except: Admin, Coordinator, Monitor, CRO, Sponsor
     */
    const userProfile = useAppSelector(selectUserProfile);

    const displayPharmacy: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );

    useEffect(() => {
        fetchPharmaciesData().then()
        fetchData().then()
    }, [dispatch, page]);

    useEffect(() => {
        fetchData().then()
    }, [searchParams]);

    const columns = () => {
        const columns: any[] = [
            {
                title: <Box ml="20px">{t('firstname-and-lastname')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Consultation.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.owner?.fullname}</Box>
                ),
                key: 'firstnameAndLastname',
            },
            {
                title: <Box ml="20px">{t('date-of-order')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Consultation.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.createdAt}</Box>
                ),
                key: 'createdAt',
            },
            {
                title: <Box ml="20px">{t('pharmacy')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Consultation.statusClosed(row?.status) ? '50%' : '100%'}}
                    >{row?._embedded?.pharmacies?.number}</Box>
                ),
                key: 'pharmacy',
            },
            {
                title: <Box ml="20px">{t('status')}</Box>,
                render: (row: any) => (
                    <Box style={{opacity: Consultation.statusClosed(row?.status) ? '50%' : '100%'}}>
                        {/*{t(ecrfStatusDictionary[row?.status])}*/}
                    </Box>
                ),
                key: 'status',
            },
        ]

        columns.push({
            title: <Box ml="20px">{t('form')}</Box>,
            noWrap: true,
            render: (row: any) => (
                <Box style={{opacity: Consultation.statusClosed(row?.status) ? '50%' : '100%'}}>
                    <Box display="inline-flex">
                        <Button
                            component={Link}
                            to={ROUTES_CONSULTATION.DETAILS(row?.id || "")}
                            variant="outlined"
                            color="primary"
                            size="small"
                            endIcon={<CallMadeIcon/>}
                        >
                            {t('go')}
                        </Button>
                    </Box>
                </Box>
            ),
            key: 'actions',
        })

        return columns
    }

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel/>
            <Table
                pagination={{
                    current: page,
                    pageSize: data.itemsPerPage,
                    total: data.totalItems,
                }}
                onChangePage={(page) => setPage(page)}
                data={data._embedded?.item || []}
                tableName={t('consultations')}
                columns={columns()}
                isLoading={isDataLoading}
            />
        </ColoredBox>
    );
};

export default Consultations;
