export default class Patient {
    /**
     * Close status
     * @param status
     */
    public static statusClosed(status: any) {
        switch (status) {
            case 5:
            case 6:
            case 7: {
                return true;
            }
        }

        return false;
    }
}
