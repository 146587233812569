import React, { useEffect, useState } from 'react';
import {
    NotificationNotificationNotificationReadIdReadContentReadContextReadSeenReadCreatedRead as Notification
} from 'types/api.types';
import { selectUserProfile } from 'core/redux/userSlice';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import NavBar from './../DashboardNavBar';
import DashboardSidebarBar, { SidebarItem } from './../DashboardSidebarBar';
import DashboardNotifyBar from './../DashboardNotifyBar';
import {
    ROUTES_USERS,
    ROUTES_DASHBOARD,
} from 'commons/constants/paths';
import { roles } from 'commons/auth/roles';
import { fetchNotifications, selectNotifications } from 'features/notifications/redux/notificationsSlice';
import {useTranslation} from "react-i18next";
import BasketBar from "../BasketBar";
import {useSelector} from "react-redux";
import { selectBasket } from "../../../../../features/basket/redux/basketSlice";

export interface NavProps {
    notificationsData?: {
        newNotificationsCounter?: number;
        notificationsListOptions: Notification[];
    };
}

const Nav = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(selectNotifications);
    const userProfile = useAppSelector(selectUserProfile);
    const { t } = useTranslation();
    const basket = useAppSelector(selectBasket);

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [basketOpen, setBasketOpen] = useState(false);
    const [notifyListOpen, setNotifyListOpen] = useState(false);
    const itemsPerPage = 15;

    const fetch = () => {
        dispatch(fetchNotifications({ page: 1, itemsPerPage }));
    };

    useEffect(() => {
        fetch();
        const interval = setInterval(async () => {
            await fetch;
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    let allowed = userProfile.roles.some((role: string) => roles.admin.includes(role));

    const navVariant = () => {
        const sidebarItemsData: SidebarItem[] = [
            {
                variant: 'routerLink',
                label: t('main-panel'),
                subLabel: t('main-panel-desc'),
                to: ROUTES_DASHBOARD.DASHBOARD,
                isShowing: true,
            },
            {
                variant: 'routerLink',
                label: t('users'),
                to: ROUTES_USERS.LIST,
                isShowing: allowed,
            },
        ];

        return sidebarItemsData;
    };

    return (
        <>
            <NavBar
                basketCounter={basket?.quantity || 0}
                newNotificationsCounter={notifications?.filter((el) => el.seenAt === null).length}
                onBurgerIconClick={() => setIsSidebarOpen((prev) => !prev)}
                onBasketIconClick={() => setBasketOpen((prev) => !prev)}
                onNotifyIconClick={() => setNotifyListOpen((prev) => !prev)}
            />
            <DashboardSidebarBar
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
                sidebarItemsData={navVariant()}
                userName={`${userProfile.firstname} ${userProfile.lastname}`}
                userEmail={userProfile.email}
                userRole={userProfile.roles[0]}
            />
            <BasketBar
                isOpen={basketOpen}
                onClose={() => setBasketOpen((prev) => !prev)}
                items={basket?.items || []}
            />
            <DashboardNotifyBar
                isOpen={notifyListOpen}
                onClose={() => setNotifyListOpen((prev) => !prev)}
                notifications={notifications}
            />
        </>
    );
};

export default Nav;