import {
    Grid,
    MenuItem,
    TextField
} from "@mui/material";
import {
    StyledSectionTitle,
    StyledInfoSectionSubtitle,
    StyledDepartmentDescription,
    StyledSplitButtonWrapper,
} from "../Default/Home.styled";
import GoogleMap from "./GoogleMap";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
    fetchPublicPharmacies, selectPharmacies,
} from "features/pharmacies/redux/pharmaciesSlice";
import { uniq } from "lodash";
import { Pharmacy as PharmacyProp } from "types/api.types";
import { useTranslation } from "react-i18next";

function Pharmacy() {
    const dispatch = useAppDispatch();
    const pharmacies = useAppSelector(selectPharmacies);
    const [page, setPage] = useState(1);
    const [pharmacyList, setPharmacyList] = useState<string[]>([]);
    const [pharmacy, setPharmacy] = useState<string | undefined>('');
    const [selectedPharmacy, setSelectedPharmacy] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const fetch = async () => {
        setLoading(true)

        const list = await dispatch(fetchPublicPharmacies({page})).unwrap();

        const pharmacyCityList = uniq(
            list._embedded?.item.map(
                (p: PharmacyProp) => p.name
            )
        );
        pharmacyCityList && setPharmacy(pharmacyCityList[0]);
        pharmacyCityList && setPharmacyList(pharmacyCityList);

        // set default values
        setSelectedPharmacy(list._embedded?.item[0]);
        setLoading(false)
    };

    useEffect(() => {
        // fetch();
    }, []);

    const selectPharmacy = async (value: string) => {
        setPharmacy(value);
        const current = pharmacies.data.find(
            (p: PharmacyProp) => p?.name === value
        );
        setSelectedPharmacy(current);
    };

    return (
        <Grid container spacing={2} style={{marginTop: '200px'}}>
            <Grid item xs={12} md={6}>
                <GoogleMap
                    lat={parseFloat(selectedPharmacy?.longitude) || 0}
                    lng={parseFloat(selectedPharmacy?.latitude) || 0}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <StyledSectionTitle isForDepartments className="section-title" textAlign="left">
                    {t('pharmacies')}
                </StyledSectionTitle>
                <StyledInfoSectionSubtitle>
                    {t('pharmacy')} {pharmacy}
                </StyledInfoSectionSubtitle>
                <StyledDepartmentDescription>
                    {selectedPharmacy?.name}
                </StyledDepartmentDescription>
                <StyledDepartmentDescription>
                    {selectedPharmacy?._embedded?.address?.street}
                    <br/>
                    {selectedPharmacy?._embedded?.address?.postcode} {selectedPharmacy?._embedded?.address?._embedded?.city?.name}
                </StyledDepartmentDescription>
                <StyledSplitButtonWrapper>
                    <TextField
                        name="pharmacy"
                        label={t('pharmacy')}
                        select
                        value={pharmacy}
                        onChange={(e) => selectPharmacy(e.target.value)}
                        disabled={loading}
                    >
                        {pharmacyList.map((p: string) => (
                            <MenuItem key={p} value={p}>
                                {t('pharmacy')} {p}
                            </MenuItem>
                        ))}
                    </TextField>
                </StyledSplitButtonWrapper>
            </Grid>
        </Grid>
    );
}

export default Pharmacy;
