import styled, {css} from "styled-components";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    //width: 350px;
    //z-index: 1000;
    background-color: #fff;
  }
`;

export const StyledDrawerContentWrapper = styled.div`
  padding-top: 56px;
`;

export const StyledHideNotifyMenuBtn = styled(Typography)`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

interface StyledNoteContentProps {
    $isNewNotify?: boolean;
}

export const StyledNoteContent = styled(Typography)<StyledNoteContentProps>`
  ${({$isNewNotify}) =>
    $isNewNotify &&
    css`
      font-weight: bold;
    `}
`;

export const StyledNotificationBox = styled.div`
  display: grid;
  grid-template-columns: 0fr 1fr;
  border-bottom: 1px solid #d4d4d4;
  padding: 15px;

//   &:hover {
//     background-color: #6bdfe1;
//   }
`;
