export const getRoleByRoleId = (role: string | string[] | undefined) => {
  if (!role) {
    return '-';
  }

  if (Array.isArray(role)) {
    role = role[0];
  }

  switch (role) {
    case 'ROLE_ADMIN':
      return 'Administrator';
    case 'ROLE_DOCTOR':
      return 'Doktor';
    case 'ROLE_PHARMACIST':
      return 'Farmaceuta';
    default:
      return role;
  }
};
