import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    FormGroup,
    FormHelperText,
    Grid,
    Typography
} from "@mui/material";
import { ROUTES_DASHBOARD } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import TextFieldFormik from "components/Form/TextFieldFormik";
import { register, UserRegisterRequest } from "core/redux/userSlice";
import { Form, Formik } from "formik";
import { useSnackbar} from "notistack";
import { Navigate, useNavigate } from "react-router-dom";
import ColoredBox from "components/ColoredBox";
import yup from "commons/yup";
import { getToken, isTokenExpired } from "commons/auth/tokens";
import { PROJECT_NAME } from "commons/constants/env";
import { useTranslation } from "react-i18next";
import { fetchAgreements, selectAgreements } from "core/redux/dictionariesSlice";
import { fetchPublicPharmacies } from "../../../features/pharmacies/redux/pharmaciesSlice";
import { map } from "lodash";
import CheckboxFormik from "../../../components/Form/CheckboxFormik";
import ReactHtmlParser from 'react-html-parser';
import LoadingBox from "../../../components/LoadingBox";
import GoogleMapsPlacesFormik from "../../../components/Form/GoogleMapsPlacesFormik";
import PharmaciesFormik from "../../../components/Form/PharmaciesFormik";

const validationSchema = yup.object({
    firstname: yup.string()
        .min(2)
        .max(50)
        .required(),
    lastname: yup.string()
        .min(2)
        .max(50)
        .required(),
    email: yup.string().email().required(),
    plainPassword: yup.string().required(),
    phone: yup.string()
        .matches(new RegExp('[0-9]{9}'), 'Phone number is not valid')
        .min(9, 'too short')
        .max(9, 'too long')
        .required(),
    pharmacy: yup.object().shape({
        value: yup.string().required(),
        id: yup.string(),
    }),
    address: yup.object({
        street: yup.string().required(),
        number: yup.string().required(),
        city: yup.object().shape({
            description: yup.string().required(),
            structured_formatting: yup.string(),
        }),
        postcode: yup.string().required(),
        // country: yup.string().required(),
    }),
    // agreements: yup.array().of(
    //     yup.lazy(value => {
    //         console.log('agreements')
    //         console.log(value)
    //         const { checked } = value // Get the value of checked field
    //
    //         return checked
    //             ? yup.object().shape({
    //                 value: yup.string().required(), // validate only if checked is true
    //                 checked: yup.boolean()
    //             })
    //             : yup.object().shape({
    //                 value: yup.string(),
    //                 checked: yup.boolean()
    //             })
    //     })
    // ),
    agreements: yup.lazy((values) => {
        console.log('agreements')
        console.log(values)
        const {name, code, secondary_code, number} = values
            return yup.object().shape({
                value: yup.string().required(),
            })
        // return Yup.mixed().notRequired()
    })
}).test('TestCondition', function (value) {
    // your global test code...
    console.log('TestCondition')
    console.log(value)
});

interface Option {
    id: string;
    value: string;
}

const Register = () => {
    const dispatch = useAppDispatch();
    const agreements = useAppSelector(selectAgreements);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const token = getToken();
    const { t } = useTranslation();
    // const { values } = useFormikContext<UserRegisterRequest>();
    const [isDataLoading, setDataLoading] = useState(true)
    const [isProcessing, setProcessing] = useState(false)
    const [valueOptions, setValueOptions] = useState<readonly Option[]>([]);
    const previousController = useRef();

    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        plainPassword: '',
        pharmacy: '',
        address: {
            street: '',
            number: '',
            city: '',
            postcode: '',
            // country: '',
        },
        agreements: []
    };

    useEffect(() => {
        handleInitData();
    }, [dispatch]);

    const handleSubmit = async (values: typeof initialValues) => {
        setProcessing(true);

        try {
            await dispatch(register(values as UserRegisterRequest));
            navigate(ROUTES_DASHBOARD.DASHBOARD);
        } catch (error: any) {
            enqueueSnackbar(t('invalid-data-entered-please-re-enter-the-correct-details'), {
                variant: 'error',
            });
        }

        setProcessing(false);
    };

    if (token && !isTokenExpired(token)) {
        return <Navigate to={ROUTES_DASHBOARD.DASHBOARD}/>;
    }

    const handleInitData = async () => {
        setDataLoading(true);

        try {
            await dispatch(fetchAgreements({"type.name": "user_register"}));
        } catch (error: any) {
            enqueueSnackbar(t('invalid-data-entered-please-re-enter-the-correct-details'), {
                variant: 'error',
            });
        }

        setDataLoading(false);
    };

    const handleData = async (value: any) => {
        setProcessing(true);

        try {
            await dispatch(fetchPublicPharmacies());
        } catch (error: any) {
            enqueueSnackbar(t('invalid-data-entered-please-re-enter-the-correct-details'), {
                variant: 'error',
            });
        }

        setProcessing(false);
    };

    const onInputChange = (event: any, value: any, reason: any) => {
        if (value) {
            handleData(value);
        } else {
            setValueOptions([]);
        }
    };

    return (
        <Box
            minHeight="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pl={2}
            pr={2}
        >
            <ColoredBox p={5} width={352} maxWidth="100%">
                {isDataLoading ? (
                    <LoadingBox/>
                ) : (
                    <>
                        <Typography variant="body2">{PROJECT_NAME}</Typography>
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({ errors, touched, validateField, validateForm }) => (
                                <Form>
                                    <Typography variant="h5">{t('registration')}</Typography>
                                    <Box mt={3.5}>
                                        <TextFieldFormik
                                            margin="dense"
                                            name="firstname"
                                            label={t('firstname')}
                                            type="text"
                                            variant="filled"
                                            fullWidth
                                        />
                                        <TextFieldFormik
                                            margin="dense"
                                            name="lastname"
                                            label={t('lastname')}
                                            type="text"
                                            variant="filled"
                                            fullWidth
                                        />
                                        <TextFieldFormik
                                            margin="dense"
                                            name="email"
                                            label={t('email')}
                                            type="email"
                                            variant="filled"
                                            fullWidth
                                            helperText={t('registration-email-input-helptext')}
                                        />
                                        <TextFieldFormik
                                            margin="dense"
                                            name="plainPassword"
                                            label={t('password')}
                                            type={'text'}
                                            variant="filled"
                                            fullWidth
                                            helperText={t('registration-password-input-helptext')}
                                        />
                                        <TextFieldFormik
                                            margin="dense"
                                            name="phone"
                                            label={t('phone')}
                                            type={'tel'}
                                            variant="filled"
                                            fullWidth
                                        />
                                        <Box mt={2}>
                                            {/*<Autocomplete*/}
                                            {/*    id="pharmacy"*/}
                                            {/*    options={valueOptions}*/}
                                            {/*    onInputChange={onInputChange}*/}
                                            {/*    getOptionLabel={(option) => option.value || ''}*/}
                                            {/*    fullWidth={true}*/}
                                            {/*    renderInput={(params) => (*/}
                                            {/*        <TextField*/}
                                            {/*            {...params}*/}
                                            {/*            name="pharmacy"*/}
                                            {/*            label={t('pharmacy')}*/}
                                            {/*            variant="filled"*/}
                                            {/*            error={touched.pharmacy && Boolean(errors.pharmacy)}*/}
                                            {/*        />*/}
                                            {/*    )}*/}
                                            {/*/>*/}
                                            <Box>
                                                <PharmaciesFormik
                                                    margin="dense"
                                                    name="pharmacy"
                                                    label={t('pharmacy')}
                                                    variant="filled"
                                                />
                                            </Box>
                                        </Box>
                                        <Box mt={2}>
                                            <Typography variant="body2">{t('address')}:</Typography>
                                            <TextFieldFormik
                                                margin="dense"
                                                name="address.street"
                                                label={t('street')}
                                                variant="filled"
                                                fullWidth
                                            />
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextFieldFormik
                                                    margin="dense"
                                                    name="address.postcode"
                                                    label={t('postcode')}
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFieldFormik
                                                    margin="dense"
                                                    name="address.number"
                                                    label={t('number')}
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box mt={2}>
                                            <GoogleMapsPlacesFormik
                                                margin="dense"
                                                name="address.city"
                                                label={t('city')}
                                                variant="filled"
                                            />
                                        </Box>
                                        <Box mt={2}>
                                            {map(agreements?.data, (item, key) =>
                                                <FormGroup>
                                                    <CheckboxFormik
                                                        key={key}
                                                        // name='agreements'
                                                        name={`agreements[${key}]`}
                                                        label={ReactHtmlParser(item?.content)}
                                                        value={item?.id}
                                                        // required={item?.required}
                                                    />
                                                    <FormHelperText style={{ color: 'red' }}>
                                                        {/*{touched?.agreements?.[key] && errors?.agreements?.[key]*/}
                                                        {touched?.agreements && errors?.agreements
                                                            // ? touched.agreements[key] && errors.agreements[key]
                                                            ? touched.agreements && errors.agreements
                                                            : ''
                                                        }
                                                    </FormHelperText>
                                                </FormGroup>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box mt={2} display="flex" justifyContent="flex-end">
                                        <Box>
                                            <Button type="submit" color="primary" variant="contained" disabled={isProcessing}>
                                                {t('sign-up')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </ColoredBox>
        </Box>
    );
};

export default Register;
