import { Route, Routes } from "react-router-dom";
import {
    ROUTES_BASKET,
    ROUTES_CORE,
    ROUTES_DASHBOARD,
} from "commons/constants/paths";
import HomeDefault from "./views/Home/Default";
import Logout from "./views/Logout";
import Dashboard from "features/dashboard/views/Dashboard";
import BasketFull from "features/basket/views/Basket";
import Page404 from "./views/Page404";
import Login from "./views/Login";
import Register from "./views/Register";
import React from "react";
import PrivateRoute from "commons/router/PrivateRoute";
import ResetPassword from "./views/ResetPassword";
import ForgotPassword from "./views/ForgotPassword";
import { roles } from 'commons/auth/roles';

export default function App() {
    const renderHomePage = () => {
        return <HomeDefault/>;
    }

    return (
        <Routes>
            {/* CORE ROUTES */}
            <Route path={ROUTES_CORE.HOME} element={renderHomePage()} />
            <Route path={ROUTES_CORE.FORGOT_PASSWORD} element={<ForgotPassword/>} />
            <Route path={ROUTES_CORE.RESET_PASSWORD(":token")} element={<ResetPassword/>} />
            <Route path={ROUTES_CORE.LOGIN} element={<Login />} />
            <Route path={ROUTES_CORE.LOGOUT} element={<Logout />} />
            <Route path={ROUTES_CORE.REGISTER} element={<Register />} />

            {/* DASHBOARD ROUTES */}
            <Route path={ROUTES_DASHBOARD.DETAILS(":type")} element={
                <PrivateRoute roles={roles.all}><Dashboard /></PrivateRoute>
            } />
            <Route path={ROUTES_DASHBOARD.DASHBOARD} element={
                <PrivateRoute roles={roles.all}><Dashboard /></PrivateRoute>
            } />

            <Route path={ROUTES_BASKET.MODULE} element={
                <PrivateRoute roles={roles.all}><BasketFull /></PrivateRoute>
            } />

            <Route path="*" element={<Page404 />}/>
        </Routes>
    );
}
