import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import {
    ProductProductReadIdReadNameReadCreatedRead as Product
} from "../../../types/api.types";

export interface ProductState {
    products: {
        itemsPerPage: number;
        totalItems: number;
        data: any[];
    };
    details: any | null;
}

const initialState: ProductState = {
    products: {
        itemsPerPage: 0,
        totalItems: 0,
        data: [],
    },
    details: null,
};

type FetchOrderParams = {
    page?: number | undefined;
};

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async ({ page }: FetchOrderParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<
                HalResource<Product[]>
            >('/api/products', {
                headers: {
                    'Content-Type': 'application/hal+json',
                    accept: 'application/hal+json',
                },
                params: {
                    page,
                },
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchProduct = createAsyncThunk(
    'products/fetchProduct', async (id: string, thunkAPI) => {
        try {
            const response =
                await axiosSecureInstance.get<Product>(
                    `/api/products/${id}`
                );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
});

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        resetProducts(state) {
            state.products = initialState.products;
        },
        resetProduct(state) {
            state.details = initialState.details;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products.data = action.payload._embedded?.item || [];
            state.products.itemsPerPage = action.payload.itemsPerPage;
            state.products.totalItems = action.payload.totalItems;
        });
        builder.addCase(fetchProduct.fulfilled, (state, action) => {
            state.details = action.payload;
        });
    },
});

export const selectProducts = (state: RootState) => state.products.products.products;
export const selectProduct = (state: RootState) => state.products.products.details;

export const {
    resetProducts,
    resetProduct,
} = productsSlice.actions;

export default productsSlice.reducer;
