import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import {
    ConsultationConsultationReadIdReadTitleReadDescriptionReadCreatedRead as Consultation
} from "../../../types/api.types";

export interface OrderState {
    consultations: {
        itemsPerPage: number;
        totalItems: number;
        data: any[];
    };
    details: any | null;
}

const initialState: OrderState = {
    consultations: {
        itemsPerPage: 0,
        totalItems: 0,
        data: [],
    },
    details: null,
};

type FetchOrderParams = {
    page?: number | undefined;
};

export const fetchConsultations = createAsyncThunk(
    'consultations/fetchConsultations',
    async ({ page }: FetchOrderParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<
                HalResource<Consultation[]>
            >('/api/consultations', {
                headers: {
                    'Content-Type': 'application/hal+json',
                    accept: 'application/hal+json',
                },
                params: {
                    page,
                },
            });
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchConsultation = createAsyncThunk(
    'consultations/fetchConsultation', async (id: string, thunkAPI) => {
        try {
            const response =
                await axiosSecureInstance.get<Consultation>(
                    `/api/consultations/${id}`
                );
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
});

export const consultationsSlice = createSlice({
    name: 'consultations',
    initialState,
    reducers: {
        resetConsultations(state) {
            state.consultations = initialState.consultations;
        },
        resetConsultation(state) {
            state.details = initialState.details;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConsultations.fulfilled, (state, action) => {
            state.consultations.data = action.payload._embedded?.item || [];
            state.consultations.itemsPerPage = action.payload.itemsPerPage;
            state.consultations.totalItems = action.payload.totalItems;
        });
        builder.addCase(fetchConsultation.fulfilled, (state, action) => {
            state.details = action.payload;
        });
    },
});

export const selectConsultations = (state: RootState) => state.consultations.consultations.consultations;
export const selectConsultation = (state: RootState) => state.consultations.consultations.details;

export const {
    resetConsultations,
    resetConsultation,
} = consultationsSlice.actions;

export default consultationsSlice.reducer;
