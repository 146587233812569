import {
    AppBar,
    Box,
    Button,
    IconButton,
    Menu,
    Tooltip,
    Typography
} from "@mui/material";
import {
    StyledToolbar,
    StyledBadge,
    StyledMenuItem,
    StyledSystemVersion,
} from "./DashboardNavBar.styled";
import {
    AddCircle,
    AddShoppingCart,
    AccountCircle as AccountCircleIcon,
    Menu as MenuIcon,
    Notifications as NotificationsIcon
} from "@mui/icons-material";
import ColoredIconWrapper from "components/ColoredIconWrapper";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchNotifications, readNotifications } from "features/notifications/redux/notificationsSlice";
import { COLOR_BG_NAV, PROJECT_EMAIL } from "commons/constants/env";
import { useSnackbar } from "notistack";
import { roles } from 'commons/auth/roles';
import { selectUserProfile } from "core/redux/userSlice";
import { useTranslation } from "react-i18next";
import ToggleLang from "../../../../../i18n/components/ToggleLang";

export interface DashboardNavBarProps {
    basketCounter?: number;
    newNotificationsCounter?: number;
    onBasketIconClick?: () => void;
    onNotifyIconClick?: () => void;
    onBurgerIconClick: () => void;
}

const DashboardNavBar = ({
  basketCounter,
  newNotificationsCounter,
  onBasketIconClick,
  onNotifyIconClick,
  onBurgerIconClick,
}: DashboardNavBarProps) => {
    const navigate          = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const userProfile       = useAppSelector(selectUserProfile);
    const { t } = useTranslation();
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLSpanElement>(null);

    const handleCloseUserMenu = () => {
        setUserMenuAnchorEl(null);
    };

    const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    let displayBasket: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );

    // let displayToggleLang: boolean = userProfile.roles.some(
    //     (role: string) => roles.admin.includes(role)
    // );

    /**
     * This function is used to create read notify request
     */
    const itemsPerPage = 15;
    const dispatch = useAppDispatch()
    const readNotify = async () => {
        await dispatch(readNotifications());
        await dispatch(fetchNotifications({page: 1, itemsPerPage}));
    }

    /**
     * This function will display info text on NavBar depending on API URL
     */
    const displaySystemType = () => {
        let API = process.env.REACT_APP_API_URL || ''
        if (API.includes('dev')) {
            return t('system-developer-version');
        } else if (API.includes('preprod')) {
            return t('system-test-version')
        } else {
            return ''
        }
    }

    /**
     * This function is used to clear all cookies
     */
    const clearCacheData = () => {
        const cookies = document.cookie.split(";");

        for (const cookie of cookies) {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
        window.location.reload();
    };

    return (
        <>
            <AppBar position="fixed" style={{backgroundColor: COLOR_BG_NAV, boxShadow: "none"}}>
                <StyledToolbar>
                    <Box mr={2}>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={onBurgerIconClick}>
                            <MenuIcon/>
                        </IconButton>
                    </Box>
                    <Box flexGrow={1} display="grid" gridTemplateColumns="10% 1fr 1fr" alignItems={'center'}>
                        <Typography variant="h6">Menu</Typography>
                        <StyledSystemVersion> {displaySystemType()} </StyledSystemVersion>
                    </Box>
                    <Box display="flex">
                        {displayBasket && (
                            <Box ml={3}>
                                <IconButton size="small" onClick={onBasketIconClick}>
                                    <StyledBadge badgeContent={basketCounter} color="error">
                                        <ColoredIconWrapper color="secondary">
                                            <AddShoppingCart />
                                        </ColoredIconWrapper>
                                    </StyledBadge>
                                </IconButton>
                            </Box>
                        )}
                        <Box ml={3} onClick={() => readNotify()}>
                            <IconButton size="small" onClick={onNotifyIconClick}>
                                <StyledBadge badgeContent={newNotificationsCounter} color="primary">
                                    <ColoredIconWrapper color="secondary">
                                        <NotificationsIcon/>
                                    </ColoredIconWrapper>
                                </StyledBadge>
                            </IconButton>
                        </Box>
                        {/*{displayToggleLang && (*/}
                        {/*    <Box ml={3}>*/}
                        {/*        <ToggleLang />*/}
                        {/*    </Box>*/}
                        {/*)}*/}
                        <Box ml={3}>
                            <Tooltip title={`${t('help-desc')} ${PROJECT_EMAIL}.`}>
                                <span>
                                    <Button size={'small'} onClick={() => clearCacheData()}>
                                        {t('help')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                        <Box ml={3}>
                            <IconButton size="small" onClick={handleProfileClick}>
                                <ColoredIconWrapper color="secondary">
                                    <AccountCircleIcon/>
                                </ColoredIconWrapper>
                            </IconButton>
                        </Box>
                    </Box>
                </StyledToolbar>
            </AppBar>

            <Menu
                anchorEl={userMenuAnchorEl}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={!!userMenuAnchorEl}
                onClose={handleCloseUserMenu}
                style={{marginTop: "30px"}}
            >
                <StyledMenuItem
                    component={Link}
                    to={ROUTES_CORE.LOGOUT}
                >
                    {t('logout')}
                </StyledMenuItem>
            </Menu>
        </>
    );
};

export default DashboardNavBar;
