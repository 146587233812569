import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  combineReducers,
  Reducer,
  Middleware
} from "@reduxjs/toolkit";
import coreReducer from "core/redux/index";
// import usersFeatureReducer from "features/users/redux/index";
import basketFeatureReducer from "features/basket/redux/index";
import commentsFeatureReducer from "features/comments/redux/index";
import consultationsFeatureReducer from "features/consultations/redux/index";
import dashboardFeatureReducer from "features/dashboard/redux/index";
import notificationsReducer from "features/notifications/redux/index"
import ordersFeatureReducer from "features/orders/redux/index"
import patientsFeatureReducer from "features/patients/redux/index";
import pharmaciesFeatureReducer from "features/pharmacies/redux/index";
import productsFeatureReducer from "features/products/redux/index";
// import fileFeatureReducer from "features/documentation-management/redux/index";
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

export const throwMiddleware: Middleware = () => (next) => (action) => {
  next(action);
  if (action?.error) {
    throw action.payload;
  }
};

const basketPersistConfig = {
  key: 'basket',
  storage,
}

const combinedReducer = combineReducers({
  // basket: basketFeatureReducer,
  basket: persistReducer(basketPersistConfig, basketFeatureReducer),
  comments: commentsFeatureReducer,
  consultations: consultationsFeatureReducer,
  core: coreReducer,
  // users: usersFeatureReducer,
  dashboard: dashboardFeatureReducer,
  notifications: notificationsReducer,
  orders: ordersFeatureReducer,
  patients: patientsFeatureReducer,
  pharmacies: pharmaciesFeatureReducer,
  products: productsFeatureReducer,
  // file: fileFeatureReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'user/logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(throwMiddleware),
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        // serializableCheck: false,
      }).concat(throwMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
