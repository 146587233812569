import React from "react";
import Box from "@mui/material/Box";
import { StyledText } from "./LoadingBoxr.styled";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";

export interface LoadingBoxProps {
    dataLoading?: string;
}

const LoadingBox = (props: LoadingBoxProps) => {
    const { t } = useTranslation();
    return <Box display="grid" style={{ placeItems: 'center', height: 'calc(100vh - 300px)', width: '100%' }}>
        <Box display="grid" style={{placeItems: 'center', gap: '15px'}}>
            <CircularProgress />
            <StyledText>{props?.dataLoading || t('loading-data') + '...'}</StyledText>
        </Box>
    </Box>;
};

export default LoadingBox;
